<template>
  <div class="oci-check-in-out-body"> 
    <b-card class="oci-check-in-out-card">     
        <b-row>      
          <b-col cols="4">
            Job:
          </b-col>
          <b-col>
            {{ site.jobNumber }}
          </b-col>
          <!--
          <b-col>
            <div class="oci-map-header">
              <div class="float-right">
                <feather-icon
                  id="notifications"
                  icon="ChevronLeftIcon"
                  size="24"
                  @click="GoToBackProjectsListPage"
                />
              </div>
            </div>    
          </b-col>      
          -->   
        </b-row>       
        <b-row>
          <b-col cols="4">
            Open:
          </b-col>
          <b-col>
            {{ site.openTime + ' - ' + site.closeTime }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Client:
          </b-col>
          <b-col>
            {{ site.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Address:
          </b-col>
          <b-col>
            {{ site.address }}&nbsp;
            <b-link :href="getPostCodeLink(site.postcode)">
              {{ site.postcode }}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Contact:
          </b-col>
          <b-col>
            {{ site.contactName }}<br>
            <b-link :href="getPhoneLink(site.contactPhone)">
              {{ site.contactPhone }}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Task:
          </b-col>
          <b-col>
            {{ task }}
          </b-col>
        </b-row>           
    </b-card>

    <RejectButton :is-map="false" />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AcceptButton from '@/components/ui/AcceptButton.vue'
import RejectButton from '@/components/ui/RejectButton.vue'
import store from '@/store'
import router from '@/router'

export default {
  name: 'CheckInOut',
  components: {
    AcceptButton,
    RejectButton,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
  computed: {
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
    ...mapGetters({ task: 'app/getCurrentTask' }),
    ...mapGetters({ userAssignmentSet: 'app/getUserAssignmentSet' }),
    ...mapGetters({ totalAssignments: 'app/getTotalAssignments' }),    
    ...mapGetters({ oneAlreadyCheckedIn: 'app/getOneAlreadyCheckedIn' }),  
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),  
    ...mapGetters({ previousAssignmentsID: 'app/getPreviousAssignmentsID' }),    
  },
  methods: {
    getPostCodeLink(postCode) {
      return `https://maps.google.com/maps?q=${postCode}`
    },
    getPhoneLink(phoneNo) {
      return `tel:${phoneNo}`
    },
    GoToBackProjectsListPage() {
      // store.commit('app/setUserAssignmentSet', false)
      store.commit('app/changeUserAssignmentsID', this.previousAssignmentsID)
      this.$router.go(-1)
    },
  },
}
</script>
