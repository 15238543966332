<template>
  <div class="oci-projects-button-container">
    <b-button
      type="submit"
      @click="RejectAssignment"
    >
      Reject
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { messageBox } from '@/components/ui/MessageBox'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'

export default {
  name: 'RejectButton',
  components: {
    BButton,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),    
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ operativeSiteId: 'app/getCurrentSiteOperativeId' }),
    ...mapGetters({ site: 'app/getCurrentSite' }),
    buttonContainerCss() {
      // Only if checked in do we show checked out style
      if (this.checkedInStatus === 'in') return 'oci-check-out-button-container'

      return 'oci-check-in-button-container'
    },
  },
  methods: {
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },  
    RejectAssignment() {
      swal.fire({
        title: '',
        html: 'Are you sure you want to reject this project?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'I\'m sure',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmIcon: 'primary',
          confirmButton: 'btn btn-primary order-2 ml-1',
          cancelIcon: 'outline-primary',
          cancelButton: 'btn btn-outline-primary order-1 mr-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed === true) {
          checkInOutMethods.Reject(this.assignmentID)
          //this.$router.push('/projects');
          return          
        }
      })
    },
    checkNextAssignment() {
      loginMethods.GetWebUserTokenCreate()
    },     
  },
}
</script>
